import * as React from 'react'
import ReactMarkdown from 'react-markdown'

import {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const PhpClient = (props: {}) => {
    const file_name = 'README.md';
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`../assets/mkdown/php/${file_name}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res));
            })
            .catch(err => console.log(err));
    });

    return (
        <>
            <div>
                <a href={"/client-api-php.tar.gz"} target={"_blank"}>
                    <button className={"download-cli"}>Download the latest version</button>
                </a>
            </div>
            <ReactMarkdown
                children={post.substr(0, post.search(/## API Endpoints/g))}
                includeElementIndex={false}
                className={'doc-api'}
            />
        </>
    )
}

export default PhpClient
