import * as React from 'react';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';

import './App.css';
import php from './components/php'
import js from "./components/js";
import api from "./components/api";

import NavbarScroller from './components/Navbar';


interface IProps {
}

interface IState {
    loaded?: boolean;
    logoClasse: string
}

const navigation = {
    brand: {name: "Sorewards-doc", to: "/"},
    links: [
        {name: "Api", to: "/swagger.json", target: "_blank"},
        {name: "Doc & client Php", to: "/clis/php"},
        {name: "Doc & client Javascript", to: "/clis/js"},
        // {name: "Developement", to: "/dev"},
        // {name: "Graphic Design", to: "/design"},
        // {name: "Contact", to: "/contact"},
    ]
}

class App extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loaded: true,
            logoClasse: "App-logo"
        };
    }

    render() {
        const {brand, links} = navigation;

        return (
            <div className="App">
                <header className="App-header">
                    <NavbarScroller brand={brand} links={links}/>
                </header>
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route component={php} path="/clis/php"/>
                            <Route component={js} path="/clis/js"/>
                            <Route component={api} path="/api"/>
                            <Redirect from={"/"} to={"/api"}/>
                        </Switch>
                    </BrowserRouter>
                </div>
            </div>
        );
    }
}

export default App;
